<template>
    <div class="channel_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>报备筛选</span>
                    <el-input
                        size="small"
                        style="width: 150px"
                        placeholder="请输入报备名称"
                        v-model.trim="name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <el-select
                        v-model="searchVal.field"
                        size="small"
                        style="width: 100px; margin-right: 10px"
                        placeholder="请选择"
                        class="slee"
                    >
                        <el-option label="报备时间" :value="1"></el-option>
                        <el-option label="回收时间" :value="2"></el-option>
                    </el-select>
                    <el-radio-group v-model="searchVal.followTime" size="small">
                        <el-radio-button
                            label="今日"
                            @click.native.prevent="clickFollow('今日')"
                        ></el-radio-button>
                        <el-radio-button
                            label="近7天"
                            @click.native.prevent="clickFollow('近7天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本月"
                            @click.native.prevent="clickFollow('本月')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        size="small"
                        v-model="searchVal.followDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 220px; margin-left: 10px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 10px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="getTableData2(active)"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 16px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search-two">
                <p class="left">
                    <span>报备品牌</span>
                    <el-radio-group
                        v-model="searchVal.productType"
                        size="small"
                        @change="getTableData2(active)"
                    >
                        <el-radio-button label="腾讯"></el-radio-button>
                        <el-radio-button label="阿里"></el-radio-button>
                        <el-radio-button label="网易"></el-radio-button>
                    </el-radio-group>
                    <span>报备渠道</span>
                    <el-select
                        v-model="searchVal.channelManageId"
                        size="small"
                        style="width: 220px"
                        placeholder="请选择报备渠道"
                        clearable
                        @change="getTableData2(active)"
                    >
                        <el-option
                            v-for="item in channeManagelListData"
                            :key="item.id"
                            :label="item.channelName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </p>
            </div>
        </div>
        <div class="table-title">
            <div>
                <div class="title_left_btn">
                    <span
                        @click="getTableData2(3)"
                        :class="active == 3 ? 'active' : ''"
                        >已报备</span
                    >
                    <span
                        @click="getTableData2(18)"
                        :class="active == 18 ? 'active' : ''"
                        >已成交</span
                    >
                    <span
                        @click="getTableData2(17)"
                        :class="active == 17 ? 'active' : ''"
                        >已放弃</span
                    >
                    <span
                        @click="getTableData2(19)"
                        :class="active == 19 ? 'active' : ''"
                        >已到期</span
                    >
                </div>
            </div>
            <button class="add" @click="onAddReport" v-if="btnP.add">
                <i class="iconfont icon-faqibaobei"></i> 报备
            </button>
        </div>
        <Table
            ref="table"
            @onReportDetail="onReportDetail"
            @totalNum="totalNum"
        ></Table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <el-drawer
            :visible.sync="drawerReport"
            :direction="direction"
            size="500px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增报备
                </div>
            </template>
            <AddReport @close="handleClose" ref="addReport" />
        </el-drawer>
        <el-drawer
            :visible.sync="drawerDetail"
            :direction="direction"
            size="500px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    报备详情
                </div>
            </template>
            <Detail @close="handleClose" ref="detail" />
        </el-drawer>
    </div>
</template>

<script>
import { getChannelInfo } from '@/api/report/report.js';
import { channeManagelList } from '@/api/reportchannel/reportchannel';
import Table from '../table/table.vue';
import AddReport from '../add/addReport.vue';
import Detail from '../detail/detail.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            searchVal: {
                field: 1,
                followTime: '',
                followDate: [],
                reportStatus: 3,
                productType: '腾讯',
            },
            isPage: true,
            currentPage: 1,
            pagesize: 20,
            total: 0,
            active: 3,
            direction: 'rtl',
            drawerReport: false,
            drawerDetail: false,
            name: '',
            btnP: {},
            tableRow: {},
            channeManagelListData: [],
        };
    },
    components: {
        Table,
        AddReport,
        Detail,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            this.getData();
            this.channeManagelList();
        },
        onAddReport() {
            this.drawerReport = true;
            setTimeout(() => {
                this.$refs.addReport.getData(this.btnP);
            }, 0);
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        totalNum(num) {
            this.total = num;
        },
        // 时间输入框变化
        expireTime() {
            if (
                this.searchVal.followDate &&
                this.searchVal.followDate.length > 0
            ) {
                this.searchVal.followTime = '';
            }
        },
        channeManagelList() {
            let data = {
                param: {},
                pageNum: 0,
                pageSize: 0,
            };

            channeManagelList(data).then((res) => {
                this.channeManagelListData = res.data.list;
            });
        },

        onReportDetail(row) {
            this.tableRow = row;
            this.drawerDetail = true;
            this.getChannelInfo();
        },
        getChannelInfo() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            getChannelInfo(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerDetail) {
                        this.$refs.detail.getData(res.data);
                    }
                }
            });
        },
        getTableData2(num) {
            this.searchVal.reportStatus = num;
            this.active = num;
            this.currentPage = 1;
            this.getData();
        },
        getTableData() {
            this.currentPage = 1;
            this.searchVal = {
                field: 1,
                followTime: '',
                followDate: [],
                reportStatus: 3,
            };
            this.active = 0;
            this.getData();
        },
        getData() {
            this.$refs.table.getData(
                this.name,
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        clickFollow(e) {
            console.log(e);
            e === this.searchVal.followTime
                ? (this.searchVal.followTime = '')
                : (this.searchVal.followTime = e);
            this.searchVal.followDate = [];
        },
        handleClose() {
            this.drawerReport = false;
            this.drawerDetail = false;
            this.getData();
            sessionStorage.removeItem('customerlist');
        },
        searchEmpty() {
            this.searchVal = {
                field: 1,
                followTime: '',
                followDate: [],
                reportStatus: 3,
            };
            this.currentPage = 1;
            this.name = '';
            this.active = 3;
            this.getData();
        },
    },
};
</script>

<style lang="scss" scoped>
.channel_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 20px 0 12px;
            height: 26px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            background: #2370eb;
            i {
                margin-right: 6px;
                font-size: 12px;
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
.slee {
    /deep/ .el-input__inner {
        font-weight: 600;
        color: #333;
    }
}
</style>
