<template>
    <div class="add_report_box">
        <el-form
            ref="form"
            style="margin-top: 20px; margin-left: 60px; padding-bottom: 50px"
            label-position="left"
            :model="form"
            size="small"
            label-width="100px"
        >
            <el-form-item label="报备品牌" style="margin-left: -10px" required>
                <el-select
                    v-model="form.productType"
                    size="small"
                    style="width: 240px"
                    placeholder="请选择报备品牌"
                    @change="accountBrief"
                    :disabled="productTypeDis"
                >
                    <el-option label="腾讯企业邮箱" :value="1"></el-option>
                    <el-option label="阿里企业邮箱" :value="2"></el-option>
                    <el-option label="网易企业邮箱" :value="4"></el-option>
                </el-select>
                <div class="info" v-if="form.productType">
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    <span>最大报备名额：</span
                    ><em style="color: #d0021b">{{
                        brief.maxReportNumber || 0
                    }}</em>
                    <span>已报备：</span
                    ><em style="color: #2370eb">{{
                        brief.assignedNumber + brief.channelReportNumber || 0
                    }}</em>
                </div>
            </el-form-item>

            <el-form-item label="报备名称" required style="margin-left: -10px">
                <el-input
                    v-model="form.reportName"
                    placeholder="请输入报备企业名称"
                    style="width: 240px"
                    @change="getReportName()"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系人" style="margin-left: -10px">
                <el-input
                    v-model="form.contactName"
                    placeholder="请输入联系人"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" style="margin-left: -10px">
                <el-input
                    v-model="form.contactPhone"
                    placeholder="请输入联系电话"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="报备渠道" required style="margin-left: -10px">
                <el-select
                    v-model="form.channelManageId"
                    size="small"
                    style="width: 240px"
                    filterable
                    placeholder="请选择或搜索报备渠道"
                >
                    <el-option
                        :label="item.channelName"
                        v-for="item in channelListData"
                        :key="item.id"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所在地" required style="margin-left: -10px">
                <el-cascader
                    placeholder="请选择所在地"
                    style="width: 240px"
                    clearable
                    v-model="address"
                    :options="$address.cascader()"
                ></el-cascader>
            </el-form-item>
            <!-- 阿里域名 -->
            <div v-show="form.productType == 2 || form.productType == 4">
                <el-form-item
                    :label="'域名' + (index + 1)"
                    required
                    style="margin-left: -10px; position: relative"
                    v-for="(item, index) in nicheAliDomainDOList"
                    :key="index"
                    class="domain_box"
                >
                    <el-input
                        style="width: 240px"
                        v-model="item.domain"
                        placeholder="请输入域名"
                        @input="domainInput(item.domain, index)"
                    ></el-input>
                    <p
                        style="color: #666666; font-size: 12px"
                        v-if="!item.tag && item.domain"
                    >
                        <i
                            class="el-icon-warning-outline"
                            style="color: #f5a623"
                        ></i>
                        本域名重复无法报备，请修改或删除
                    </p>
                    <div class="domain_rules_box" v-if="item.domain">
                        <el-button
                            v-if="item.tag"
                            type="primary"
                            class="el-icon-success"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-else
                            class="el-icon-warning"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </div>
                    <div class="domain_update_box">
                        <el-button
                            v-if="index == 0"
                            @click="addDomain"
                            type="primary"
                            class="el-icon-plus"
                            style="background: #fff"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            @click="delDomain(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                            style="background: #fff"
                        ></el-button>
                    </div>
                </el-form-item>
            </div>
            <!-- 腾讯域名 -->
            <el-form-item
                label="域名"
                v-show="form.productType == 1"
                required
                style="margin-left: -10px"
            >
                <el-input
                    v-model="form.reportDomain"
                    placeholder="请输入域名(www开头)"
                    style="width: 240px"
                    @input="checkTmDomain"
                ></el-input>
                <p
                    style="color: #666666; font-size: 12px"
                    v-if="!tag && form.reportDomain"
                >
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    本域名重复无法报备，请修改或删除
                </p>
                <div class="domain_rules_box" v-if="form.reportDomain">
                    <el-button
                        v-if="tag"
                        type="primary"
                        class="el-icon-success"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-else
                        class="el-icon-warning"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item label="回收时间" style="margin-left: -10px" required>
                <el-date-picker
                    v-model="form.reportExpireTime"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 240px"
                    type="date"
                    placeholder="请选择回收时间"
                    @change="expireTime"
                >
                </el-date-picker>
                <el-radio-group
                    style="display: block; margin-top: 8px"
                    v-model="followTime"
                    size="small"
                >
                    <el-radio-button
                        label="30天"
                        @click.native.prevent="clickFollow('30天')"
                    ></el-radio-button>
                    <el-radio-button
                        label="60天"
                        @click.native.prevent="clickFollow('60天')"
                    ></el-radio-button>
                    <el-radio-button
                        label="90天"
                        @click.native.prevent="clickFollow('90天')"
                    ></el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="报备库" style="margin-left: -10px" required>
                <el-select
                    v-model="form.libraryCategory"
                    size="small"
                    style="width: 240px"
                    placeholder="请选择报备库"
                    @change="changeLibraryCategory"
                >
                    <el-option
                        :label="item.accountName"
                        v-for="item in accountListData"
                        :key="item.id"
                        :value="item.id"
                    ></el-option>
                </el-select>
                <p
                    style="color: #999; font-size: 12px"
                    v-if="form.libraryCategory"
                >
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    剩余报备名额：{{ surplus }}
                </p>
            </el-form-item>
            <el-form-item label="报备状态" style="margin-left: -10px" required>
                <el-select
                    v-model="form.reportStatus"
                    size="small"
                    style="width: 240px"
                    placeholder="请选择报备状态"
                >
                    <el-option label="已报备" :value="3"></el-option>
                    <el-option label="已放弃" :value="17"></el-option>
                    <el-option label="已成交" :value="18"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" style="margin-left: -10px">
                <el-input
                    type="textarea"
                    v-model="form.remark"
                    size="small"
                    placeholder="请输入备注"
                    style="width: 240px"
                    rows="6"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                size="mini"
                style="background: #2370eb; border-color: #d5d6df; color: #fff"
                @click="saveReport"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
import {
    reportAdd,
    reportList,
    customerList,
    addChannelInfo,
    accountGet,
    accountList,
    checkDomain,
} from '@/api/report/report.js';
import { accountGet as newAccountGet } from '@/api/report/newReport.js';
import {
    aliNotReport,
    contactAdd,
    addCustomer,
} from '@/api/user/ae/customer.js';
import { channeManagelList } from '@/api/reportchannel/reportchannel.js';
import { channelInfoList } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                reportDomain: '',
                reportStatus: 3,
            },
            nicheAliDomainDOList: [{}],

            address: [],
            tag: '',
            row: {},
            surplus: 0,
            followTime: '',
            brief: {},
            channelListData: [],
            accountListData: [],
            productTypeDis: false,
            btnP: {},
            isOk: true,
        };
    },
    components: {},
    methods: {
        getData(btnP, row = '') {
            if (btnP.aliMail && !btnP.tmMail) {
                this.form.productType = 2;
                this.accountBrief(2);
                this.productTypeDis = true;
            }
            if (btnP.tmMail && !btnP.aliMail) {
                this.form.productType = 1;
                this.accountBrief(1);
                this.productTypeDis = true;
            }
            this.btnP = btnP;
            sessionStorage.setItem('customerlist', 1);
            this.row = row;
            this.channeManagelList();
        },
        channeManagelList() {
            let data = {
                param: {
                    status: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            channeManagelList(data).then((res) => {
                if (res.code == 200) {
                    this.channelListData = res.data.list;
                }
            });
        },
        accountBrief(productType) {
            let data = {
                param: {
                    productType,
                    adminId: sessionStorage.getItem('adminId'),
                },
            };
            if (this.btnP.tmReport) {
                data.param.productType = 5;
                newAccountGet(data).then((res) => {
                    if (res.code == 200) {
                        this.brief = res.data;
                        if (productType == 2) {
                            this.form.reportDomain = '';
                        }
                        this.accountList();
                    }
                });
            } else {
                accountGet(data).then((res) => {
                    if (res.code == 200) {
                        this.brief = res.data;
                        if (productType == 2) {
                            this.form.reportDomain = '';
                        }
                        this.accountList();
                    }
                });
            }
        },
        accountList(name) {
            let data = {
                param: {
                    productType: this.form.productType,
                },
                pageNum: 0,
                pageSize: 0,
            };
            if (this.btnP.tmReport) {
                data.param.productType = 5;
            }
            if (name) {
                data.param.name = name;
            }
            accountList(data).then((res) => {
                if (res.code == 200) {
                    this.accountListData = res.data.list;
                }
            });
        },
        changeLibraryCategory(val) {
            if (val) {
                let obj = this.accountListData.find((item) => {
                    return item.id == val;
                });
                this.surplus =
                    Number(obj.maxReportNumber) -
                    Number(obj.consumedNumber) -
                    Number(obj.channelConsumedNumber);
            }
        },
        addDomain() {
            this.nicheAliDomainDOList.push({});
        },
        delDomain(index) {
            this.nicheAliDomainDOList.splice(index, 1);
        },
        checkTmDomain() {
            this.checkDomain(1, [this.form.reportDomain]);
        },
        checkDomain(productType, domainList) {
            let data = {
                param: { productType, domainList },
            };
            checkDomain(data).then((res) => {
                if (res.code == 200) {
                    this.tag = true;
                } else {
                    this.tag = false;
                }
            });
        },
        clickFollow(e) {
            e === this.followTime
                ? (this.followTime = '')
                : (this.followTime = e);
            if (e == this.followTime && e == '30天') {
                this.form.reportExpireTime =
                    this.$searchTime.getNextDate(new Date(), -30) + ' 00:00:00';
            } else if (e == this.followTime && e == '60天') {
                this.form.reportExpireTime =
                    this.$searchTime.getNextDate(new Date(), -60) + ' 00:00:00';
            } else if (e == this.followTime && e == '90天') {
                this.form.reportExpireTime =
                    this.$searchTime.getNextDate(new Date(), -90) + ' 00:00:00';
            } else {
                this.form.reportExpireTime = '';
            }
        },
        expireTime() {
            this.followTime = '';
        },
        // 域名重复校验
        domainInput(val, index) {
            if (/^www/.test(val)) {
                this.nicheAliDomainDOList[index].domain = '';
                return this.$message.error('域名不能包含www');
            }
            if (
                this.nicheAliDomainDOList.some((item, idx) => {
                    if (idx != index) {
                        if (item.domain && item.domain == val) {
                            return true;
                        }
                    }
                })
            ) {
                this.nicheAliDomainDOList[index].tag = false;
                return;
            }
            let data = {
                param: {
                    productType: 2,
                    domainList: [val],
                },
            };
            checkDomain(data).then((res) => {
                console.log(res);
                if (res.code != 200) {
                    this.nicheAliDomainDOList[index].tag = false;
                } else {
                    this.nicheAliDomainDOList[index].tag = true;
                }
                this.nicheAliDomainDOList = JSON.parse(
                    JSON.stringify(this.nicheAliDomainDOList)
                );
            });
            // reportList(data).then((res) => {
            //     if (res.code == 200) {

            //         // this.tableData = res.data.list;
            //     }
            // });
        },
        Turn(srt) {
            var reg = /[\(]/g,
                reg2 = /[\)]/g;
            return srt.replace(reg, '（').replace(reg2, '）');
        },
        getReportName() {
            this.isOk = true;
            if (this.form.reportName != '') {
                let obj = {
                    param: {
                        name: this.form.reportName,
                    },
                };
                channelInfoList(obj).then((res) => {
                    if (res.code == 200) {
                        if (res.data && res.data.list.length > 0) {
                            for (let i in res.data.list) {
                                if (
                                    res.data.list[i].reportStatus == 2 ||
                                    res.data.list[i].reportStatus == 8 ||
                                    res.data.list[i].reportStatus == 9 ||
                                    res.data.list[i].reportStatus == 3
                                ) {
                                    return (this.isOk = false);
                                }
                            }
                        }
                    }
                });
            }
        },
        // 保存报备
        saveReport(reportStatus) {
            if (this.surplus <= 0) {
                return this.$message.error(
                    '当前报备库名额不足，请选择其他报备库'
                );
            }
            this.form.provinceId = Number(this.address[0]) || 0;
            this.form.cityId = Number(this.address[1]) || 0;

            if (!this.form.productType) {
                return this.$message.error('请选择报备品牌');
            }
            if (!this.form.reportName) {
                return this.$message.error('请输入报备名称');
            }
            // if (!this.form.contactName) {
            //     return this.$message.error('请输入联系人');
            // }
            // if (!this.form.contactPhone) {
            //     return this.$message.error('请输入联系电话');
            // }
            if (!this.form.provinceId) {
                return this.$message.error('请选择所在地');
            }
            if (this.form.productType == 2 || this.form.productType == 4) {
                for (let i in this.nicheAliDomainDOList) {
                    if (!this.nicheAliDomainDOList[i].domain) {
                        return this.$message.error('请输入域名');
                    }
                    if (!this.nicheAliDomainDOList[i].tag) {
                        return this.$message.error('域名不能重复');
                    }
                }
            } else {
                if (!this.form.reportDomain) {
                    return this.$message.error('请输入域名');
                }
                if (!/^www/.test(this.form.reportDomain)) {
                    this.form.reportDomain = '';
                    return this.$message.error('腾讯域名以www开头');
                }
            }
            if (!this.form.reportExpireTime) {
                return this.$message.error('请选择回收时间');
            }
            if (!this.form.libraryCategory) {
                return this.$message.error('请选择报备库');
            }
            if (!this.form.reportStatus) {
                return this.$message.error('请选择报备状态');
            }
            this.form.reportName = this.Turn(this.form.reportName);

            let data = {
                param: {
                    ...this.form,
                },
            };
            if (this.form.productType == 2 || this.form.productType == 4) {
                data.param.nicheChannelDomainDOList =
                    this.nicheAliDomainDOList.map((item) => {
                        return {
                            domain: item.domain,
                        };
                    });
                delete this.form.reportDomain;
            }
            console.log(data);
            addChannelInfo(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
                if (res.code == 484) {
                    this.$message.error('报备的名额大于分配的最大名额了');
                }
            });
            //    if (this.isOk) { } else {
            //         this.$message.error('当前报备名称已报备，申请失败');
            //     }
        },

        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.nicheAliDomainDOList = [
                {
                    domain: '',
                },
            ];
            this.address = [];
            this.$emit('close');
            sessionStorage.removeItem('customerlist');
        },
    },
};
</script>

<style lang="scss" scoped>
.add_report_box {
    width: 100%;
    height: 100%;
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        background: #fafafa;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }

    .domain_box {
        /deep/ .el-input__inner {
            padding-right: 20px !important;
        }
        .el-button--mini.is-circle {
            padding: 2px;
            background: #fff;
            color: #2370eb;
            font-weight: 600;
            width: 20px;
            height: 20px;
            border: 2px solid #2370eb;
        }

        .domain_update_box {
            position: absolute;
            right: 80px;
            top: 0px;
        }
    }
    .domain_rules_box {
        position: absolute;
        right: 110px;
        top: 2px;
        .el-button--mini.is-circle {
            padding: 2px;
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: none;
            margin-left: 0;
            font-size: 16px;
            font-weight: 600;
        }
        .el-icon-success {
            color: #46a136 !important;
        }
        .el-icon-warning {
            color: #f5a623 !important;
        }
    }
    .bottom-but {
        width: 480px;
        line-height: 48px;
        text-align: right;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
.info {
    font-size: 12px;
    span {
        color: #666666;
    }
    em {
        font-weight: normal;
        font-style: normal;
        margin-right: 5px;
    }
    span:last-child {
        margin-left: 10px;
    }
}
</style>
