<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="8" show-overflow-tooltip> </el-table-column>
            <el-table-column label="商机ID" width="110" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.partner }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备品牌" width="110" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.productType == 1 ? '腾讯企业邮箱' : '阿里企业邮箱' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        <el-button
                            v-if="btnP.detail"
                            type="text"
                            style="color: #2370eb"
                            @click="onReportDetail(scope.row)"
                            >{{ scope.row.reportName || '- -' }}</el-button
                        >
                        <span v-else>{{ scope.row.reportName || '- -' }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备渠道" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.channelManageName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="域名" width="145" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-if="scope.row.productType == 1">
                        {{ scope.row.reportDomain || '- -' }}
                    </div>

                    <div v-else>
                        <el-tooltip effect="dark" placement="bottom">
                            <div>
                                {{ returnDomain(scope.row) }}
                            </div>
                            <div slot="content" class="item">
                                <p
                                    v-for="item in scope.row.nicheChannelDomainBOList"
                                    :key="item.id"
                                >
                                    <span
                                        style="
                                            display: inline-block;
                                            width: 150px;
                                            text-align: left;
                                        "
                                    >
                                        {{ item.domain }}
                                    </span>
                                </p>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备时间" width="93" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.reportStartTime">{{
                        scope.row.reportStartTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="回收时间" width="93" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.reportExpireTime">{{
                        scope.row.reportExpireTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备状态" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ $MailStatus.reportStatus(scope.row.reportStatus) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备库" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.libraryName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="所在地" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ $address.address(scope.row.provinceId, scope.row.cityId) }}
                    </div>
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import { channelInfoList } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            btnP: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(name, searchVal, pageNum, pageSize, btnP) {
            this.btnP = btnP;
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum,
                pageSize,
            };
            if (name) {
                data.param.name = name;
                searchVal = {};
            }
            var startTime = '';
            var endTime = '';
            if (searchVal.followTime != '') {
                let date = new Date();
                searchVal.followDate = [];
                if (searchVal.followTime == '今日') {
                    endTime = this.$searchTime.getNextDate(date, -1);
                    startTime = this.$searchTime.getNextDate(date, 0);
                } else if (searchVal.followTime == '近7天' && searchVal.field == 2) {
                    startTime = this.$searchTime.getNextDate(date, 0);
                    endTime = this.$searchTime.getNextDate(date, -7);
                } else if (searchVal.followTime == '近7天' && searchVal.field == 1) {
                    startTime = this.$searchTime.getNextDate(date, 6);
                    endTime = this.$searchTime.getNextDate(date, -1);
                } else if (searchVal.followTime == '本月') {
                    startTime = this.$searchTime.DateUtil.getStartDayOfMonth();
                    endTime = this.$searchTime.DateUtil.getEndDayOfMonth();
                }
            }
            if (searchVal.followDate && searchVal.followDate.length > 0) {
                startTime = searchVal.followDate[0];
                endTime = searchVal.followDate[1];
            }

            if (searchVal.field == 1 && startTime) {
                data.param.startReportStartDate = startTime;
                data.param.endReportStartDate = endTime;
            } else if (searchVal.field == 2 && startTime) {
                data.param.startReportExpireDate = startTime;
                data.param.endReportExpireDate = endTime;
            }
            if (searchVal.productType) {
                data.param.productType =
                    searchVal.productType == '腾讯' ? 1 : searchVal.productType == '阿里' ? 2 : 4;
            }
            if (searchVal.reportStatus) {
                data.param.reportStatus = searchVal.reportStatus;
            }
            if (searchVal.channelManageId) {
                data.param.channelManageId = searchVal.channelManageId;
            }
            channelInfoList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        onReportDetail(row) {
            this.$emit('onReportDetail', row);
        },
        // 域名处理
        returnDomain(row) {
            if (row.nicheChannelDomainBOList && row.nicheChannelDomainBOList.length > 0) {
                return row.nicheChannelDomainBOList[0].domain;
            } else {
                return '- -';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
}
/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.el-button + .el-button {
    margin-left: 0;
}
.item {
    p + p {
        margin-top: 10px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        font-size: 12px;
        text-align: center;
        height: 25px;
        line-height: 25px;
        border-radius: 3px;
        margin-left: 10px;
    }
    .success {
        border: 1px solid #2370eb;
        i {
            color: #2370eb;
        }
    }
    .warning {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
    .wait {
        border: 1px solid #f5a623;
        i {
            color: #f5a623;
        }
    }
    .faid {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
}
</style>
